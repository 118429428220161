var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search" },
    [
      _c(
        "Card",
        [
          _c(
            "Row",
            {
              staticClass: "operation",
              attrs: { align: "middle", justify: "space-between" },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "Button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showType == "tree",
                          expression: "showType == 'tree'",
                        },
                      ],
                      attrs: { type: "primary", icon: "md-add" },
                      on: { click: _vm.add },
                    },
                    [_vm._v("添加子部门")]
                  ),
                  _c(
                    "Button",
                    { attrs: { icon: "md-add" }, on: { click: _vm.addRoot } },
                    [_vm._v("添加一级部门")]
                  ),
                  _c(
                    "Button",
                    { attrs: { icon: "md-trash" }, on: { click: _vm.delAll } },
                    [_vm._v("批量删除")]
                  ),
                  _c(
                    "Button",
                    {
                      attrs: { icon: "md-refresh" },
                      on: { click: _vm.getParentList },
                    },
                    [_vm._v("刷新")]
                  ),
                  _c("Input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showType == "list",
                        expression: "showType == 'list'",
                      },
                    ],
                    staticStyle: { width: "250px" },
                    attrs: {
                      suffix: "ios-search",
                      placeholder: "输入部门名搜索",
                      clearable: "",
                    },
                    on: { "on-change": _vm.search },
                    model: {
                      value: _vm.searchKey,
                      callback: function ($$v) {
                        _vm.searchKey = $$v
                      },
                      expression: "searchKey",
                    },
                  }),
                  _c(
                    "i-switch",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showType == "tree",
                          expression: "showType == 'tree'",
                        },
                      ],
                      staticStyle: { "margin-left": "5px" },
                      attrs: { size: "large" },
                      model: {
                        value: _vm.strict,
                        callback: function ($$v) {
                          _vm.strict = $$v
                        },
                        expression: "strict",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                        _vm._v("级联"),
                      ]),
                      _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                        _vm._v("单选"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "RadioGroup",
                    {
                      attrs: { type: "button" },
                      model: {
                        value: _vm.showType,
                        callback: function ($$v) {
                          _vm.showType = $$v
                        },
                        expression: "showType",
                      },
                    },
                    [
                      _c(
                        "Radio",
                        { attrs: { title: "树结构", label: "tree" } },
                        [_c("Icon", { attrs: { type: "md-list" } })],
                        1
                      ),
                      _c(
                        "Radio",
                        { attrs: { title: "列表", label: "list" } },
                        [_c("Icon", { attrs: { type: "ios-apps" } })],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "Row",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showType == "tree",
                  expression: "showType == 'tree'",
                },
              ],
              attrs: { type: "flex", justify: "start", gutter: 16 },
            },
            [
              _c(
                "Col",
                { attrs: { sm: 8, md: 8, lg: 8, xl: 6 } },
                [
                  _c("Alert", { attrs: { "show-icon": "" } }, [
                    _vm._v(" 当前选择编辑： "),
                    _c("span", { staticClass: "select-title" }, [
                      _vm._v(_vm._s(_vm.editTitle)),
                    ]),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.id && _vm.editTitle,
                            expression: "form.id && editTitle",
                          },
                        ],
                        staticClass: "select-clear",
                        on: { click: _vm.cancelEdit },
                      },
                      [_vm._v("取消选择")]
                    ),
                  ]),
                  _c("Input", {
                    attrs: {
                      suffix: "ios-search",
                      placeholder: "输入部门名搜索",
                      clearable: "",
                    },
                    on: { "on-change": _vm.search },
                    model: {
                      value: _vm.searchKey,
                      callback: function ($$v) {
                        _vm.searchKey = $$v
                      },
                      expression: "searchKey",
                    },
                  }),
                  _c(
                    "div",
                    { staticStyle: { position: "relative" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "tree-bar",
                          style: { maxHeight: _vm.maxHeight },
                        },
                        [
                          _c("Tree", {
                            ref: "tree",
                            attrs: {
                              data: _vm.data,
                              "load-data": _vm.loadData,
                              "show-checkbox": "",
                              "check-strictly": !_vm.strict,
                            },
                            on: {
                              "on-check-change": _vm.changeSelect,
                              "on-select-change": _vm.selectTree,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.loading
                        ? _c("Spin", { attrs: { size: "large", fix: "" } })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { sm: 16, md: 16, lg: 16, xl: 9 } },
                [
                  _c(
                    "Form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        "label-width": 100,
                        rules: _vm.formValidate,
                      },
                    },
                    [
                      _c(
                        "FormItem",
                        {
                          staticClass: "form-noheight",
                          attrs: { label: "上级部门", prop: "parentTitle" },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "flex" } },
                            [
                              _c("Input", {
                                staticStyle: { "margin-right": "10px" },
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.form.parentTitle,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "parentTitle", $$v)
                                  },
                                  expression: "form.parentTitle",
                                },
                              }),
                              _c(
                                "Poptip",
                                {
                                  attrs: {
                                    transfer: "",
                                    trigger: "click",
                                    placement: "right-start",
                                    title: "选择上级部门",
                                    width: "250",
                                  },
                                },
                                [
                                  _c("Button", { attrs: { icon: "md-list" } }, [
                                    _vm._v("选择部门"),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "tree-bar tree-select",
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _c("Tree", {
                                        attrs: {
                                          data: _vm.dataEdit,
                                          "load-data": _vm.loadData,
                                        },
                                        on: {
                                          "on-select-change":
                                            _vm.selectTreeEdit,
                                        },
                                      }),
                                      _vm.loadingEdit
                                        ? _c("Spin", {
                                            attrs: { size: "large", fix: "" },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "FormItem",
                        { attrs: { label: "部门名称", prop: "title" } },
                        [
                          _c("Input", {
                            model: {
                              value: _vm.form.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "title", $$v)
                              },
                              expression: "form.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "FormItem",
                        { attrs: { label: "部门负责人", prop: "mainHeader" } },
                        [
                          _c("user-select", {
                            ref: "mainHeader",
                            attrs: { multiple: "" },
                            model: {
                              value: _vm.form.mainHeader,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "mainHeader", $$v)
                              },
                              expression: "form.mainHeader",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "FormItem",
                        { attrs: { label: "副负责人", prop: "viceHeader" } },
                        [
                          _c("user-select", {
                            ref: "viceHeader",
                            attrs: { multiple: "" },
                            model: {
                              value: _vm.form.viceHeader,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "viceHeader", $$v)
                              },
                              expression: "form.viceHeader",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "FormItem",
                        { attrs: { label: "排序值", prop: "sortOrder" } },
                        [
                          _c(
                            "Tooltip",
                            {
                              attrs: {
                                trigger: "hover",
                                placement: "right",
                                content: "值越小越靠前，支持小数",
                              },
                            },
                            [
                              _c("InputNumber", {
                                attrs: { max: 1000, min: 0 },
                                model: {
                                  value: _vm.form.sortOrder,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "sortOrder", $$v)
                                  },
                                  expression: "form.sortOrder",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormItem",
                        { attrs: { label: "是否启用", prop: "status" } },
                        [
                          _c(
                            "i-switch",
                            {
                              attrs: {
                                size: "large",
                                "true-value": 0,
                                "false-value": -1,
                              },
                              model: {
                                value: _vm.form.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "status", $$v)
                                },
                                expression: "form.status",
                              },
                            },
                            [
                              _c(
                                "span",
                                { attrs: { slot: "open" }, slot: "open" },
                                [_vm._v("启用")]
                              ),
                              _c(
                                "span",
                                { attrs: { slot: "close" }, slot: "close" },
                                [_vm._v("禁用")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormItem",
                        { staticClass: "br" },
                        [
                          _c(
                            "Button",
                            {
                              attrs: {
                                loading: _vm.submitLoading,
                                disabled: !_vm.form.id || !_vm.editTitle,
                                type: "primary",
                                icon: "ios-create-outline",
                              },
                              on: { click: _vm.submitEdit },
                            },
                            [_vm._v("修改并保存")]
                          ),
                          _c("Button", { on: { click: _vm.handleReset } }, [
                            _vm._v("重置"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Alert",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showType == "list",
                  expression: "showType == 'list'",
                },
              ],
              attrs: { "show-icon": "" },
            },
            [
              _vm._v(" 已选择 "),
              _c("span", { staticClass: "select-count" }, [
                _vm._v(_vm._s(_vm.selectList.length)),
              ]),
              _vm._v(" 项 "),
              _c(
                "a",
                {
                  staticClass: "select-clear",
                  on: { click: _vm.clearSelectAll },
                },
                [_vm._v("清空")]
              ),
            ]
          ),
          _vm.showType == "list"
            ? _c("Table", {
                ref: "table",
                attrs: {
                  "row-key": "title",
                  "load-data": _vm.loadData,
                  columns: _vm.columns,
                  data: _vm.data,
                  loading: _vm.loading,
                  border: "",
                  "update-show-children": true,
                },
                on: { "on-selection-change": _vm.showSelect },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: { title: _vm.modalTitle, "mask-closable": false, width: 500 },
          model: {
            value: _vm.modalVisible,
            callback: function ($$v) {
              _vm.modalVisible = $$v
            },
            expression: "modalVisible",
          },
        },
        [
          _c(
            "Form",
            {
              ref: "formAdd",
              attrs: {
                model: _vm.formAdd,
                "label-width": 85,
                rules: _vm.formValidate,
              },
            },
            [
              _vm.showParent
                ? _c(
                    "div",
                    [
                      _c("FormItem", { attrs: { label: "上级部门：" } }, [
                        _vm._v(_vm._s(_vm.form.title)),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "FormItem",
                { attrs: { label: "部门名称", prop: "title" } },
                [
                  _c("Input", {
                    model: {
                      value: _vm.formAdd.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.formAdd, "title", $$v)
                      },
                      expression: "formAdd.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "FormItem",
                { attrs: { label: "排序值", prop: "sortOrder" } },
                [
                  _c(
                    "Tooltip",
                    {
                      attrs: {
                        trigger: "hover",
                        placement: "right",
                        content: "值越小越靠前，支持小数",
                      },
                    },
                    [
                      _c("InputNumber", {
                        attrs: { max: 1000, min: 0 },
                        model: {
                          value: _vm.formAdd.sortOrder,
                          callback: function ($$v) {
                            _vm.$set(_vm.formAdd, "sortOrder", $$v)
                          },
                          expression: "formAdd.sortOrder",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "FormItem",
                { attrs: { label: "是否启用", prop: "status" } },
                [
                  _c(
                    "i-switch",
                    {
                      attrs: {
                        size: "large",
                        "true-value": 0,
                        "false-value": -1,
                      },
                      model: {
                        value: _vm.formAdd.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.formAdd, "status", $$v)
                        },
                        expression: "formAdd.status",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                        _vm._v("启用"),
                      ]),
                      _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                        _vm._v("禁用"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "Button",
                { attrs: { type: "text" }, on: { click: _vm.cancelAdd } },
                [_vm._v("取消")]
              ),
              _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.submitLoading },
                  on: { click: _vm.submitAdd },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }